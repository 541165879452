<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{ active : modelValue }">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="payment-elements">
                <div class="setting_wpr">
                    <p class="flex items-center mb-4 bg-red-100 border border-red-200 px-5 py-2 rounded-lg text-red-500" v-if="errorMessage">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="flex-shrink-0 w-6 h-6">
                            <path class="fill-current text-red-300" d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"/>
                            <path class="fill-current text-red-500" d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"/>
                        </svg>

                        <span class="ml-3">{{ errorMessage }}</span>
                    </p>

                    <p class="flex items-center mb-4 bg-green-100 border border-green-200 px-5 py-4 rounded-lg text-green-700" v-if="paymentProcessed && successMessage">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="flex-shrink-0 w-6 h-6">
                            <circle cx="12" cy="12" r="10" class="fill-current text-green-300"/>
                            <path class="fill-current text-green-500" d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"/>
                        </svg>

                        <span class="ml-3">{{ successMessage }}</span>
                    </p>

                    <div id="payment-elements" v-if="!paymentProcessed">
                        <div v-show="requiresPaymentMethod">
                            <h1 class="mt-2 mb-4">
                                {{ `Confirm your ${payment.amount} payment` }}
                            </h1>
                            <p class="mb-5">
                                {{ `Extra confirmation is needed to process your payment. Please confirm your payment by filling out your payment details below.` }}
                            </p>

                            <Form v-slot="{ errors }" class="profile-form" ref="subscription-form">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Full name</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                            <Field type="text" id="cardholder-name" :autocomplete="'nofill'" name="name" v-model="name" rules="required" placeholder="Enter Cardholder Name" />
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Card</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.incomplete_number }">
                                            <Field autocomplete="off" type="text" name="incomplete_number" v-model="card" rules="required">
                                                <div id="card-element"></div>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="incomplete_number" class="text-danger" />
                                    </div>
                                </div>
                                <div class="action_wpr">
                                    <button
                                        id="card-button"
                                        class="btn save_btn"
                                        :class="{ 'bg-blue-400': paymentProcessing, 'bg-blue-600': !paymentProcessing }"
                                        @click="addPaymentMethod()"
                                        :disabled="paymentProcessing"
                                    >
                                        {{ `Pay ${payment.amount}` }}
                                    </button>
                                </div>
                            </Form>
                        </div>
                        <div class="action_wpr" v-show="requiresAction">
                            <button
                                id="card-button"
                                class="btn save_btn"
                                :class="{ 'bg-blue-400': paymentProcessing, 'bg-blue-600': !paymentProcessing }"
                                @click="confirmPaymentMethod()"
                                :disabled="paymentProcessing"
                            >
                                {{ `Confirm your ${payment.amount} payment` }}
                            </button>
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button @click="closeModal()" ref="goBackButton" class="btn cancel_btn">
                            Go back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {});

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapActions } from 'vuex'

    export default {
        name: 'Confirm Stripe Payment',

        data () {
            return {
                name: '',
                card: '',
                cardElement: null,
                paymentProcessing: false,
                paymentProcessed: false,
                requiresPaymentMethod: this.payment.requiresPaymentMethod,
                requiresAction: this.payment.requiresAction,
                successMessage: '',
                errorMessage: ''
            };
        },

        props: {
            modelValue: Boolean,
            payment: Object,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.requiresPaymentMethod = vm.payment.requiresPaymentMethod;
                    vm.requiresAction = vm.payment.requiresAction;

                    if (!vm.payment.isSucceeded && !vm.payment.isCancelled && !vm.payment.requiresAction) {
                        vm.configureStripe();
                    }
                }
            },
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                getSubscription: 'billingModule/getSubscription',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            addPaymentMethod: function () {
                const vm = this

                vm.paymentProcessing = true;
                vm.paymentProcessed = false;
                vm.successMessage = '';
                vm.errorMessage = '';

                stripeInstance.confirmCardPayment(vm.payment.clientSecret, {
                    payment_method: {
                        card: vm.cardElement,
                        billing_details: { name: vm.name }
                    }
                }).then(function (result) {
                    vm.paymentProcessing = false;

                    if (result.error) {
                        if (result.error.code === 'parameter_invalid_empty' && result.error.param === 'payment_method_data[billing_details][name]') {
                            vm.errorMessage = 'Please provide your name.';
                        } else {
                            vm.errorMessage = result.error.message;
                        }
                    } else {
                        vm.paymentProcessed = true;
                        vm.successMessage = 'The payment was successful.';
                        vm.refreshAuth();

                        setTimeout(function () {
                            vm.getSubscription();
                        }, 3000);
                    }
                });
            },

            confirmPaymentMethod: function () {
                const vm = this

                vm.paymentProcessing = true;
                vm.paymentProcessed = false;
                vm.successMessage = '';
                vm.errorMessage = '';

                stripeInstance.confirmCardPayment(vm.payment.clientSecret, { payment_method: vm.payment.payment_method }).then((result) => {
                    vm.paymentProcessing = false;

                    if (result.error) {
                        vm.errorMessage = result.error.message;

                        if (result.error.code === 'payment_intent_authentication_failure') {
                            vm.requestPaymentMethod();
                        }
                    } else {
                        vm.paymentProcessed = true;
                        vm.successMessage = 'The payment was successful.';
                        vm.refreshAuth();

                        setTimeout(function () {
                            vm.getSubscription();
                        }, 3000);
                    }
                });
            },

            requestPaymentMethod: function () {
                const vm = this;

                vm.configureStripe();
                vm.requiresPaymentMethod = true;
                vm.requiresAction = false;
            },

            configureStripe () {
                const vm = this;
                const elements = stripeInstance.elements();
                const style = {
                    base: {
                        color: '#5a5a5a',
                        lineHeight: '50px',
                        fontSmoothing: 'antialiased',
                        fontSize: '14px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#eb1414',
                        iconColor: '#eb1414',
                    },
                };

                vm.cardElement = elements.create('card', { style });
                vm.cardElement.mount('#card-element');
            },

            goBack () {
                const vm = this
                const button = vm.$refs.goBackButton;
                const redirect = new URL(button.dataset.redirect);

                if (vm.successMessage || vm.errorMessage) {
                    redirect.searchParams.append('message', vm.successMessage ? vm.successMessage : vm.errorMessage);
                    redirect.searchParams.append('success', !!vm.successMessage);
                }

                window.location.href = redirect;
            },
        },
    };
</script>

<style scoped>
    .bg-blue-400 {
        background-color: #63b3ed;
        background-color: rgba(99, 179, 237, 1);
    }

    .bg-blue-600 {
        background-color: #3182ce;
        background-color: rgba(49, 130, 206, 1);
    }

    #payment-elements {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    #payment-elements .form_grp .group_item {
        width: 100%;
    }

    #payment-elements form>.action_wpr {
        background: transparent;
        border-top: 0px solid #eaeaea;
    }

    .w-6 {
      width: 1.5rem;
    }

    .h-6 {
      height: 1.5rem;
    }

    .payment-elements {
        width: 100%;
        padding: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .setting_wpr {
        margin: auto;
        max-width: 500px;
    }

    .items-center {
        align-items: center;
    }

    .bg-red-100 {
        background-color: #fff5f5;
        background-color: rgba(255, 245, 245, 1);
    }

    .bg-red-200 {
        background-color: #fed7d7;
        background-color: rgba(254, 215, 215, 1);
    }

    .rounded-lg {
        border-radius: 0.5rem;
    }

    .text-red-300 {
        color: #feb2b2;
        color: rgba(254, 178, 178, 1);
    }

    .text-red-500 {
        color: #f56565;
        color: rgba(245, 101, 101, 1);
    }

    .fill-current {
      fill: currentColor;
    }

    .flex-shrink-0 {
        flex-shrink: 0;
    }

    .bg-green-100 {
        background-color: #f0fff4;
        background-color: rgba(240, 255, 244, 1);
    }

    .border-green-200 {
        border-color: #c6f6d5;
        border-color: rgba(198, 246, 213, 1);
    }

    .text-green-300 {
        color: #9ae6b4;
        color: rgba(154, 230, 180, 1);
    }

    .text-green-500 {
        color: #48bb78;
        color: rgba(72, 187, 120, 1);
    }

    .text-green-700 {
        color: #2f855a;
        color: rgba(47, 133, 90, 1);
    }
</style>
